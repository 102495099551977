// ---------------------------------------------------------------------------------------------------------------------
// Mateusz Feć Photography Website
// ---------------------------------------------------------------------------------------------------------------------
// Author: Mateusz Feć
// e-mail: kontakt@mateuszfec.pl
// www: www.mateuszfec.pl
//
// www.mfecphoto.com
//
// Copyright © All Rights Reserved
// ---------------------------------------------------------------------------------------------------------------------
//                                                   Special Images
// ---------------------------------------------------------------------------------------------------------------------
// Special images at the front of Home Page
#special-images{
    display: inline-block;
    width: 100%;
    padding-top: 90px;

    // Exception - row is bigger than usual
    div.row{
        max-width: 82rem;
    }

    // Images
    figure.single-new-photo{
        height: 25vw;
        min-height: 200px;
        max-height: 240px;
        width: 100%;
        border: 1px $page_color_6 solid;
        overflow: hidden;
        border-radius: 7px 7px 0 0;

        a{
            height: 100%;
            width: 100%;
            @include standard-animation-mix(all, $standard_animation_time, ease-in-out);
            img{
                height: 100%;
                width: 100%;
            }
        }
        a:hover{
            transform: scale(1.3);
        }
    }

    // Extra description
    .off-desc{
        margin-bottom: 0.9375rem;
        text-align: center;
        padding: 0.9375rem;
        background: $page_color_4;
        border-radius: 0 0 7px 7px;

        > span.desc-place{
            font-family: $page_font_headers;
            font-weight: 700;
            font-size: 17px;
        }
        > div.desc-wrapper{
            font-size: 15px;
            margin-top: 13px;
            span.desc-camera{
                white-space: nowrap;
            }
            span.desc-obj{
                color: $page_color_3;
            }
        }
        > div.desc-parameters{
            white-space: nowrap;
            font-size: 14px;
            margin-top: 3px;

            span.desc-param-iso{
                margin: 0 2%;
            }
            span.desc-param-f{
                margin: 0 1%;
            }
            span.desc-param-time{
                margin: 0 2%;
            }
        }
    }
}
// ---------------------------------------------------------------------------------------------------------------------
//                                                 Responsive elements
// ---------------------------------------------------------------------------------------------------------------------
@media screen and (min-width: $xpemini){ // to 329px
    // Extra anchor
    #galleries{
        height: 55px;
    }
}
@media screen and (min-width: $iphone){ // to 489px
}
@media screen and (min-width: $nexus){ // to 639px
}
@media screen and (min-width: $lumia){ // to 782px - MOBILE POINT
}
@media screen and (min-width: $ipad){ // to 1023px
    // Extra anchor
    #galleries{
        height: 75px;
    }
}
@media screen and (min-width: $notebook){ // to 1199px
}
@media screen and (min-width: $desktop){ // to end
}