// ---------------------------------------------------------------------------------------------------------------------
// Mateusz Feć Photography Website
// ---------------------------------------------------------------------------------------------------------------------
// Author: Mateusz Feć
// e-mail: kontakt@mateuszfec.pl
// www: www.mateuszfec.pl
//
// www.mfecphoto.com
//
// Copyright © All Rights Reserved
// ---------------------------------------------------------------------------------------------------------------------
//                                                     Footer
// ---------------------------------------------------------------------------------------------------------------------
// Global Footer
footer#page-footer{
    display: inline-block;
    width: 100%;
    color: $page_color_3;
    clear: both;
    padding: 40px 15px;

    // Copyrights info
    #copyrights{
        padding-top: 5px;
        padding-bottom: 5px;
        p{
            font-size: 1rem;
            line-height: 1.6rem;
            margin: 0;
        }
    }

    // Footer logo (back to top)
    #photo-logo{
        text-align: center;
        #footer-mf-photo{
            display: block;
            height: 60px;
            width: 60px;
            background: url("../images/mf-photo-silver.png") no-repeat center;
            background-size: contain;
            margin: 0 auto;
            border: none;
            @include standard-animation-mix();
        }
        #footer-mf-photo:hover, #footer-mf-photo:focus{
            background: url("../images/mf-photo-white.png") no-repeat center;
            background-size: contain;
        }
    }

    // Author info
    #mf-logo{
        #footer-mf-developer{
            display: inline-block;
            width: 180px;
            height: 45px;
            text-decoration: none;
            color: $page_color_2;
        }
        p{
            margin: 0;
        }
    }
}
// ---------------------------------------------------------------------------------------------------------------------
//                                                      Menu
// ---------------------------------------------------------------------------------------------------------------------
@media screen and (min-width: $xpemini){ // to 329px
    #copyrights{
        text-align: center;
    }
    #photo-logo {
        text-align: center;
        padding-top: 25px;
        padding-bottom: 25px;
    }
    #mf-logo{
        text-align: center;
    }
}
@media screen and (min-width: $iphone){ // to 489px
}
@media screen and (min-width: $nexus){ // to 639px
}
@media screen and (min-width: $lumia){ // to 782px - MOBILE POINT
    #copyrights{
        text-align: left;
    }
    #photo-logo {
        text-align: center;
        padding-top: 0;
        padding-bottom: 0;
    }
    #mf-logo{
        text-align: right;
    }
}
@media screen and (min-width: $ipad){ // to 1023px
}
@media screen and (min-width: $notebook){ // to 1199px
}
@media screen and (min-width: $desktop){ // to end
}