// ---------------------------------------------------------------------------------------------------------------------
// Mateusz Feć Photography Website
// ---------------------------------------------------------------------------------------------------------------------
// Author: Mateusz Feć
// e-mail: kontakt@mateuszfec.pl
// www: www.mateuszfec.pl
//
// www.mfecphoto.com
//
// Copyright © All Rights Reserved
// ---------------------------------------------------------------------------------------------------------------------
//                                                 Universal classes
// ---------------------------------------------------------------------------------------------------------------------

// Social buttons
#social-buttons{
    position: absolute;
    z-index: 2;
    top: auto;
    bottom: 25px;
    left: auto;
    right: 25px;
    max-height: 75px;
    overflow: hidden;

    a{
        display: inline-block;
        background: $page_color_1;
        border-radius: 25%;
        max-height: 75px;
        padding: 12px;
        width: auto;
        height: auto;
        div{
            display: inline-block;
            width: 50px;
            height: 50px;
            @include standard-animation-mix();
        }
    }

    a.menu-facebook-link{
        margin-right: 15px;
        div{
            background: url("../images/fb-1.png") no-repeat center;
            background-size: contain;
        }
    }
    a.menu-facebook-link:hover, a.menu-facebook-link:focus{
        div{
            background: url("../images/fb-2.png") no-repeat center;
            background-size: contain;
        }
    }
    a.menu-instagram-link{
        div{
            background: url("../images/inst-1.png") no-repeat center;
            background-size: contain;
        }
    }
    a.menu-instagram-link:hover, a.menu-instagram-link:focus{
        div{
            background: url("../images/inst-2.png") no-repeat center;
            background-size: contain;
        }
    }
}




.active-menu-logo{
    width: auto;
}

// TEMP
.post-script{
    font-family: 'Sriracha', cursive;
}



// Page Title
#page-title{
    position: absolute;
    display: inline-block;
    z-index: 1;

    h2,h3{
        margin: 0;
        font-size: 3rem;
        line-height: 3rem;
        font-family: $page_font_headers;
        font-weight: 700;
    }
    span{
        font-size: 1.4rem;
        line-height: 1.4rem;
        font-family: $page_font_extra;
        font-weight: 500;
        font-style: italic;
        text-transform: capitalize;
    }
}
#page-title.bottom-left{
    top: auto;
    left: 0;
    bottom: 0;
    border-radius: 0 15px 0 0;
    background: $page_color_1;
}
#page-title.bottom-left::after{
    content: '';
    position: absolute;
    width: 15px;
    height: 15px;
    background: url("../../assets/images/stick.png") no-repeat left bottom;
    background-size: contain;
    display: inline-block;
    top: auto;
    left: auto;
    right: -15px;
    bottom: 0;
}

// ---------------------------------------------------------------------------------------------------------------------
//                                                 Responsive elements
// ---------------------------------------------------------------------------------------------------------------------
@media all and (min-width: $xpemini){ // to 329px
    // Menu activated + Website Logo
    .active-menu-logo{
        //display: none;
        width: 0;
    }

    // Social buttons
    #social-buttons{
        display: none;
    }

    // Page Title
    #page-title{
        h2,h3{
            font-size: 2rem;
            line-height: 2rem;
        }
        span{
            font-size: 1rem;
            line-height: 1rem;
        }
    }
    #page-title.bottom-left{
        padding: 15px 20px;
    }
}
@media all and (min-width: $iphone){ // to 489px
    // Menu activated + Website Logo
    .active-menu-logo{
        width: auto;
    }
}
@media all and (min-width: $nexus){ // to 639px
    // Page Title
    #page-title{
        h2,h3{
            font-size: 2.5rem;
            line-height: 2.5rem;
        }
        span{
            font-size: 1.3rem;
            line-height: 1.3rem;
        }
    }
}
@media all and (min-width: $lumia){ // to 782px - MOBILE POINT
    // Social buttons
    #social-buttons{
        display: block;
    }

    // Page Title
    #page-title{
        h2,h3{
            font-size: 2.7rem;
            line-height: 2.7rem;
        }
        span{
            font-size: 1.3rem;
            line-height: 1.3rem;
        }
    }
    #page-title.bottom-left{
        padding: 20px 30px;
    }
}
@media all and (min-width: $ipad){ // to 1023px
    // Page Title
    #page-title{
        h2,h3{
            font-size: 3rem;
            line-height: 3rem;
        }
        span{
            font-size: 1.4rem;
            line-height: 1.4rem;
        }
    }
    #page-title.bottom-left{
        padding: 25px 45px;
    }
}
@media all and (min-width: $notebook){ // to 1199px
}
@media all and (min-width: $desktop){ // to end
}
// ---------------------------------------------------------------------------------------------------------------------
//                                             Responsive elements (height)
// ---------------------------------------------------------------------------------------------------------------------
@media all and (min-height: 0px) {
    // Social buttons
    .menu-instagram-link, .menu-facebook-link{
        height: 20px;
        width: 20px;
    }
}
@media all and (min-height: 380px) {
    // Social buttons
    .menu-instagram-link,.menu-facebook-link{
        height: 40px;
        width: 40px;
    }
}
@media all and (min-height: 500px) {
}