// ---------------------------------------------------------------------------------------------------------------------
// Mateusz Feć Photography Website
// ---------------------------------------------------------------------------------------------------------------------
// Author: Mateusz Feć
// e-mail: kontakt@mateuszfec.pl
// www: www.mateuszfec.pl
//
// www.mfecphoto.com
//
// Copyright © All Rights Reserved
// ---------------------------------------------------------------------------------------------------------------------
//                                                     Header
// ---------------------------------------------------------------------------------------------------------------------
// HEADER
#page-header{
    position: fixed;
    width: 100%;
    z-index: 3;
}
#header-background{
    position: absolute;
    width: 100%;
    height: 100%;
    @include standard-animation-mix();
}
.header-bg{
    background: $page_color_1;
    box-shadow: 0 2px 6px 2px rgba(0,0,0,.2);
    border-bottom: 1px $page_color_6 solid;
}
#page-header.static-header{
    position: static;
}
#website-logo-home.absolute-header{
    position: absolute;
}
#sidebar.absolute-sidebar{
    position: absolute;
    height: 100vh;
}
#slider-wrapper #main-slider.optimal-height{
    height: 87vh;
}
#button-menu-text.minimal{
    padding: 15px 20px;
    background: url("../../assets/images/test.png") repeat;
}

// Website Logo
.website-logo{
    position: fixed;
    z-index: 3;
    left: auto;
    top: 0;
    div{
        @include standard-animation-mix();
    }
}
// ---------------------------------------------------------------------------------------------------------------------
//                                                      Menu
// ---------------------------------------------------------------------------------------------------------------------
// Button container
#sidebar-btn {
    display: inline-block;
    vertical-align: middle;
    width: 170px;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: -170px;
    z-index: 5;
    @include standard-animation-mix();
}

// Hamburger elements
#button-wrapper{
    display: inline-block;
    width: auto;
    float: left;
    background: $page_color_1;
    @include standard-animation-mix();
}
.test-aaa{
    border-radius: 0 5px 5px 0;
    border-right: 1px $page_color_6 solid;
    border-left: 1px $page_color_6 solid;
}
#button-elements{
    display: inline-block;
    width: 20px;
    float: left;
}
.bar1, .bar2, .bar3 {
    width: 100%;
    height: 2px;
    background-color: $page_color_2;
    margin: 5px 0;
    transition: 0.4s;
    display: block;
}
.change .bar1 {
    -webkit-transform: rotate(-45deg) translate(-8px, 3px);
    transform: rotate(-45deg) translate(-8px, 3px);
    width: 140%;
    height: 3px;
}
.change .bar2 {opacity: 0;}
.change .bar3 {
    -webkit-transform: rotate(45deg) translate(-7px, -3px);
    transform: rotate(45deg) translate(-7px, -3px);
    width: 140%;
    height: 3px;
}
// Button text
#button-menu-text{
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    display: inline-block;
    line-height: 26px;
    border-radius: 0 5px 5px 0;
    @include standard-animation-mix();
}
#button-menu-text:hover{
    color: $page_color_3;
}

// Menu Sidebar
#sidebar {
    background: $page_color_1;
    width: 200px;
    height: 100%;
    display: block;
    position: fixed;
    left: -200px;
    top: 0;
    transition: left 0.3s linear;
    z-index: 5;
    border-right: 1px solid $page_color_6;

    // Standard menu elements
    a.menu-item{
        display: block;
        text-align: center;
        padding: 15px;
        text-decoration: none;
        border-bottom: 2px $page_color_4 solid;
        color: $page_color_3;
    }
    a.menu-item:hover, a.menu-item:focus{
        color: $page_color_2;
        background: $page_color_4;
    }

    // MF Logo at menu
    .menu-item.menu-mf-photo{
        width: 80px;
        background: url("../images/mf-photo-silver.png") no-repeat center;
        background-size: contain;
        border: none;
    }
    .menu-item.menu-mf-photo:hover{
        background: url("../images/mf-photo-white.png") no-repeat center;
        background-size: contain;
    }
    .menu-first-element{
        border-top: 2px $page_color_4 solid;
    }

    // Social media buttons
    #social-media{
        position: absolute;
        display: block;
        top: auto;
        bottom: 15px;
        left: 50%;
        -moz-transform: translate(-50%);
        -ms-transform: translate(-50%);
        -webkit-transform: translate(-50%);
        transform: translate(-50%);

        a.menu-facebook-link{
            display: inline-block;
            background: url("../images/fb-1.png") no-repeat center;
            background-size: contain;
            border: none;
            margin-right: 5px;
        }
        a.menu-facebook-link:hover, a.menu-facebook-link:focus{
            background: url("../images/fb-2.png") no-repeat center;
            background-size: contain;
        }
        a.menu-instagram-link{
            display: inline-block;
            background: url("../images/inst-1.png") no-repeat center;
            background-size: contain;
            border: none;
            margin-left: 5px;
        }
        a.menu-instagram-link:hover, a.menu-instagram-link:focus{
            background: url("../images/inst-2.png") no-repeat center;
            background-size: contain;
        }
    }
}

// Menu on active position
#sidebar.visible {
    left: 0;
    transition: left 0.3s linear;
}

// Language INFO
nav#sidebar{
    aside{
        display: inline-block;
        width: 100%;
        text-align: center;
    }
    .language-change{
        display: inline-block;
        padding: 0 6px 6px 6px;
        border: none;
        text-transform: uppercase;
        font-size: 16px;
        line-height: 16px;
        font-weight: bold;
        top: 0;
    }
    .language-change:hover, .language-change:active, .language-change:focus{
        background: none;
    }
}
// ---------------------------------------------------------------------------------------------------------------------
//                                              Responsive elements (width)
// ---------------------------------------------------------------------------------------------------------------------
@media all and (min-width: $xpemini){ // to 329px
    // HEADER
    #page-header{
        height: 55px;
    }
    #button-wrapper{
        padding: 14px 25px 13px 25px;
        margin-top: 0;
        border: none;
    }
    #button-menu-text{
        margin-top: 0;
        padding: 15px 20px 15px 0;
        background: none;
        border: none;
    }

    // Website Logo
    .website-logo{
        right: 5px;
        padding: 9px;
        background: none;
        border: none;

        div{
            width: 35px;
            height: 35px;
            background: url("../images/mf-photo-silver.png") no-repeat center right;
            background-size: contain;
        }
    }
    .website-logo:hover{
        div{
            background: url("../images/mf-photo-white.png") no-repeat center right;
            background-size: contain;
        }
    }

    // Button container
    //#sidebar-btn {
    //    margin: 15px 20px;
    //}
}
@media all and (min-width: $iphone){ // to 489px
}
@media all and (min-width: $nexus){ // to 639px
}
@media all and (min-width: $lumia){ // to 782px - MOBILE POINT
}
@media all and (min-width: $ipad){ // to 1023px
    // HEADER
    #page-header{
        height: 75px;
    }
    #button-wrapper{
        background: $page_color_1;
        padding: 15px 20px;
        margin-top: 25px;
        border-top: 1px $page_color_6 solid;
        border-bottom: 1px $page_color_6 solid;
    }
    #sidebar-btn:hover #button-wrapper{
        background: #000000;
    }
    #button-menu-text{
        border: 1px $page_color_6 solid;
        border-left: none;
        margin-top: 25px;
        padding: 15px 20px;
        background: url("../../assets/images/test.png") repeat;
    }

    // Website Logo
    .website-logo{
        right: 25px;
        padding: 15px 15px 15px 15px;
        background: $page_color_1;
        border-radius: 0 0 7px 7px;
        border: 1px $page_color_6 solid;
        border-top: none;

        div{
            width: 60px;
            height: 60px;
            background: url("../images/mf-photo-silver.png") no-repeat center right;
            background-size: contain;
        }
    }
    .website-logo:hover{
        div{
            background: url("../images/mf-photo-white.png") no-repeat center right;
            background-size: contain;
        }
    }

    // Button container
    //#sidebar-btn {
    //    margin: 24px 10px;
    //}
}
@media all and (min-width: $notebook){ // to 1199px
}
@media all and (min-width: $desktop){ // to end

}
// ---------------------------------------------------------------------------------------------------------------------
//                                             Responsive elements (height)
// ---------------------------------------------------------------------------------------------------------------------
@media all and (min-height: 0px) {
    // MF Logo at menu
    .menu-mf-photo{
        height: 30px;
        margin: 5px auto;
    }

    #sidebar{
        a{
            padding: 5px 15px;
        }
    }
}
@media all and (min-height: 380px) {
    // MF Logo at menu
    .menu-mf-photo{
        height: 60px;
        margin: 15px auto;
    }
}
@media all and (min-height: 500px) {
    #sidebar{
        a{
            padding: 15px;
        }
    }
}