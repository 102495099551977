// ---------------------------------------------------------------------------------------------------------------------
// Mateusz Feć Photography Website
// ---------------------------------------------------------------------------------------------------------------------
// Author: Mateusz Feć
// e-mail: kontakt@mateuszfec.pl
// www: www.mateuszfec.pl
//
// www.mfecphoto.com
//
// Copyright © All Rights Reserved
// ---------------------------------------------------------------------------------------------------------------------
//                                                   Single Page
// ---------------------------------------------------------------------------------------------------------------------
// Page main photos
#page-about-me{
    background: url("../../pages/about-me.jpg") no-repeat center center;
    background-size: cover;
}
#page-contact-me{
    background: url("https://farm3.staticflickr.com/2567/5697107145_3c27ff3cd1_m.jpg") no-repeat center center;
    background-size: cover;
}

.labels{
    background: red;
}


// Page header image
.main-page-photo{
    width: 100%;
    height: 50vh;
    min-height: 100px;
    text-align: center;
    margin-bottom: 10px;
}
.main-subpage-photo{
    max-height: 300px;
}

// Page content
#main-single-content{
    background: $page_color_4;
    text-align: center;

    // Exception for text content at website
    .row{
        max-width: 50rem;
    }

    h4, p{
        margin: 0;
    }
}
// ---------------------------------------------------------------------------------------------------------------------
//                                               Responsive elements
// ---------------------------------------------------------------------------------------------------------------------
@media screen and (min-width: $xpemini){ // to 329px
}
@media screen and (min-width: $iphone){ // to 489px
}
@media screen and (min-width: $nexus){ // to 639px
}
@media screen and (min-width: $lumia){ // to 782px - MOBILE POINT
}
@media screen and (min-width: $ipad){ // to 1023px
}
@media screen and (min-width: $notebook){ // to 1199px
}
@media screen and (min-width: $desktop){ // to end
}