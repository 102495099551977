// ---------------------------------------------------------------------------------------------------------------------
//                                                   MF Cookie Alert
// ---------------------------------------------------------------------------------------------------------------------
// MF Cookie Alert for theme-full-name Website
//
// File version: 1.2.0
// Version date: 15.08.2018
//
// Author: project-author-name
// e-mail: project-author-e-mail
// www: project-author-www
//
// Copyright © All Rights Reserved
// ---------------------------------------------------------------------------------------------------------------------
//                                                Cookie Alert Settings
// ---------------------------------------------------------------------------------------------------------------------
// Main settings
$alertHorizontalPosition: 3;            // 1 - Left    2 - Center      else - right
$alertVerticalPosition: 3;              // 1 - top     2 - Center      else - bottom
$elementsAnimationTime: 0.3s;

// Cookie HTML element dimensions
$alertWidth: 350px;
$alertWindowTopPosition: 0;
$alertWindowRightPosition: 35px;
$alertWindowBottomPosition: 15px;
$alertWindowLeftPosition: 0;
$alertPaddings: 15px;
// ---------------------------------------------------------------------------------------------------------------------
//                                                 Cookie Alert Styles
// ---------------------------------------------------------------------------------------------------------------------
// Cookie alert customization
@mixin alertStyles{
    background-color: #323334;
    border-radius: 10px;
    box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.5);
}
@mixin alertStylesMobile{
    background-color: #323334;
    border-radius: 0;
    box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.5);
}

// Alert title
@mixin alertTitle{
    font-size: 16px;
    font-weight: bold;
    color: #cdd7da;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 1);
    margin: 0 30px 9px 0;
}

// Alert text
@mixin alertText{
    font-size: 12px;
    color: #afb4bc;
    text-align: justify;
    line-height: 1.5em;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 1);
}
@mixin alertLinks{
    color: #cdd7da;
    text-decoration: underline;
    font-weight: bold;
}
@mixin alertLinksHover{
    color: #ffffff;
}

// Alert close button
@mixin alertCloseButton{
    width: 22px;
    height: 22px;
    top: 10px;
    left: auto;
    right: 10px;
    border-radius: 11px;
    line-height: 22px;
    font-size: 12px;
    color: #ffffff;
    text-align: center;
    text-decoration: none;
    background-color: #ff6000;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.75);
}
@mixin alertCloseButtonHover{
    background-color: #ff7925;
}

// ---------------------------------------------------------------------------------------------------------------------
//                                                    Cookie Alert
// ---------------------------------------------------------------------------------------------------------------------
#cookieinfo {
    position: fixed;
    overflow: hidden;
    width: $alertWidth;
    height: auto;
    padding: $alertPaddings;
    z-index: 999999999;

    * {
        margin: 0;
        padding: 0;
    }

    h6{
        @include alertTitle();
    }

    p{
        @include alertText();
        a{
            @include alertLinks();
            -webkit-transition: all $elementsAnimationTime ease-in-out;
            -moz-transition: all $elementsAnimationTime ease-in-out;
            -o-transition: all $elementsAnimationTime ease-in-out;
            transition: all $elementsAnimationTime ease-in-out;
        }
        a:hover, a:focus, a:active{
            @include alertLinksHover();
        }
    }

    // Close alert button
    a#close-cookie-alert{
        display: block;
        position: absolute;
        @include alertCloseButton();
        -webkit-transition: all $elementsAnimationTime ease-in-out;
        -moz-transition: all $elementsAnimationTime ease-in-out;
        -o-transition: all $elementsAnimationTime ease-in-out;
        transition: all $elementsAnimationTime ease-in-out;
    }
    a#close-cookie-alert:hover, a#close-cookie-alert:active, a#close-cookie-alert:focus{
        @include alertCloseButtonHover();
    }
}
// ---------------------------------------------------------------------------------------------------------------------
//                                                 Responsive elements
// ---------------------------------------------------------------------------------------------------------------------
@media screen and (min-width: $xpemini){ // to 329px
    #cookieinfo {
        width: 100%;
        top: auto;
        left: 0;
        right: 0;
        bottom: 0;
        @include alertStylesMobile();
    }
}
@media screen and (min-width: $iphone){ // to 489px
}
@media screen and (min-width: $nexus){ // to 639px
}
@media screen and (min-width: $lumia){ // to 782px - MOBILE POINT
    #cookieinfo {
        width: $alertWidth;
        @include alertStyles();

        // VERTICAL and HORIZONTAL container position
        @if($alertVerticalPosition == 1){
            top: $alertWindowTopPosition;
            bottom: 0;
        } @else if($alertVerticalPosition != 1 and $alertVerticalPosition != 2){
            top: auto;
            bottom: $alertWindowBottomPosition;
        }

        @if($alertHorizontalPosition == 1){
            left: $alertWindowLeftPosition;
            right: auto;
        } @else if($alertHorizontalPosition != 1 and $alertHorizontalPosition != 2){
            left: auto;
            right: $alertWindowRightPosition;
        }

        // At least one parameter is set to '2' means CENTER
        @if($alertVerticalPosition == 2 and $alertHorizontalPosition != 2){
            top: 50%;
            bottom: 0;
            -moz-transform: translate(-0%, -50%);
            -ms-transform: translate(-0%, -50%);
            -webkit-transform: translate(-0%, -50%);
            transform: translate(-0%, -50%);
        } @else if($alertVerticalPosition == 2 and $alertHorizontalPosition == 2){
            top: 50%;
            left: 50%;
            -moz-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
        } @else if($alertVerticalPosition != 2 and $alertHorizontalPosition == 2){
            left: 50%;
            -moz-transform: translate(-50%);
            -ms-transform: translate(-50%);
            -webkit-transform: translate(-50%);
            transform: translate(-50%);
        }
    }
}
@media screen and (min-width: $ipad){ // to 1023px
}
@media screen and (min-width: $notebook){ // to 1199px
}
@media screen and (min-width: $desktop){ // to end
}