// ---------------------------------------------------------------------------------------------------------------------
// Mateusz Feć Photography Website
// ---------------------------------------------------------------------------------------------------------------------
// Author: Mateusz Feć
// e-mail: kontakt@mateuszfec.pl
// www: www.mateuszfec.pl
//
// www.mfecphoto.com
//
// Copyright © All Rights Reserved
// ---------------------------------------------------------------------------------------------------------------------
//                                                  SPECIAL PHOTOS
// ---------------------------------------------------------------------------------------------------------------------
// SPECIAL photos
#special-image-1{
    background: url("../../photos/miniatures/photo-010.jpg") no-repeat center center;
    background-size: cover;
}
.special-image-2{
    background: url("../../photos/miniatures/photo-005.jpg") no-repeat center center;
    background-size: cover;
}
.special-image-3{
    background: url("../../photos/miniatures/photo-016.jpg") no-repeat center center;
    background-size: cover;
}
// ---------------------------------------------------------------------------------------------------------------------
//                                                      PHOTOS
// ---------------------------------------------------------------------------------------------------------------------
.gallery-image-25{
    background: url("../../photos/miniatures/photo-025.jpg") no-repeat center center;
    background-size: cover;
}
.gallery-image-25-normal{
    background: url("../../photos/normal/photo-025.jpg") no-repeat center center;
    background-size: cover;
}
.gallery-image-24{
    background: url("../../photos/miniatures/photo-024.jpg") no-repeat center center;
    background-size: cover;
}
.gallery-image-24-normal{
    background: url("../../photos/normal/photo-024.jpg") no-repeat center center;
    background-size: cover;
}
.gallery-image-23{
    background: url("../../photos/miniatures/photo-023.jpg") no-repeat center center;
    background-size: cover;
}
.gallery-image-23-normal{
    background: url("../../photos/normal/photo-023.jpg") no-repeat center center;
    background-size: cover;
}
.gallery-image-22{
    background: url("../../photos/miniatures/photo-022.jpg") no-repeat center center;
    background-size: cover;
}
.gallery-image-22-normal{
    background: url("../../photos/normal/photo-022.jpg") no-repeat center center;
    background-size: cover;
}
.gallery-image-21{
    background: url("../../photos/miniatures/photo-021.jpg") no-repeat center center;
    background-size: cover;
}
.gallery-image-21-normal{
    background: url("../../photos/normal/photo-021.jpg") no-repeat center center;
    background-size: cover;
}
.gallery-image-20{
    background: url("../../photos/miniatures/photo-020.jpg") no-repeat center center;
    background-size: cover;
}
.gallery-image-20-normal{
    background: url("../../photos/normal/photo-020.jpg") no-repeat center center;
    background-size: cover;
}
.gallery-image-19{
    background: url("../../photos/miniatures/photo-019.jpg") no-repeat center center;
    background-size: cover;
}
.gallery-image-19-normal{
    background: url("../../photos/normal/photo-019.jpg") no-repeat center center;
    background-size: cover;
}
.gallery-image-18{
    background: url("../../photos/miniatures/photo-018.jpg") no-repeat center center;
    background-size: cover;
}
.gallery-image-18-normal{
    background: url("../../photos/normal/photo-018.jpg") no-repeat center center;
    background-size: cover;
}
.gallery-image-17{
    background: url("../../photos/miniatures/photo-017.jpg") no-repeat center center;
    background-size: cover;
}
.gallery-image-17-normal{
    background: url("../../photos/normal/photo-017.jpg") no-repeat center center;
    background-size: cover;
}
.gallery-image-16{
    background: url("../../photos/miniatures/photo-016.jpg") no-repeat center center;
    background-size: cover;
}
.gallery-image-16-normal{
    background: url("../../photos/normal/photo-016.jpg") no-repeat center center;
    background-size: cover;
}
.gallery-image-15{
    background: url("../../photos/miniatures/photo-015.jpg") no-repeat center center;
    background-size: cover;
}
.gallery-image-15-normal{
    background: url("../../photos/normal/photo-015.jpg") no-repeat center center;
    background-size: cover;
}
.gallery-image-14{
    background: url("../../photos/miniatures/photo-014.jpg") no-repeat center center;
    background-size: cover;
}
.gallery-image-14-normal{
    background: url("../../photos/normal/photo-014.jpg") no-repeat center center;
    background-size: cover;
}
.gallery-image-13{
    background: url("../../photos/miniatures/photo-013.jpg") no-repeat center center;
    background-size: cover;
}
.gallery-image-13-normal{
    background: url("../../photos/normal/photo-013.jpg") no-repeat center center;
    background-size: cover;
}
.gallery-image-12{
    background: url("../../photos/miniatures/photo-012.jpg") no-repeat center center;
    background-size: cover;
}
.gallery-image-12-normal{
    background: url("../../photos/normal/photo-012.jpg") no-repeat center center;
    background-size: cover;
}
.gallery-image-11{
    background: url("../../photos/miniatures/photo-011.jpg") no-repeat center center;
    background-size: cover;
}
.gallery-image-11-normal{
    background: url("../../photos/normal/photo-011.jpg") no-repeat center center;
    background-size: cover;
}
.gallery-image-10{
    background: url("../../photos/miniatures/photo-010.jpg") no-repeat center center;
    background-size: cover;
}
.gallery-image-10-normal{
    background: url("../../photos/normal/photo-010.jpg") no-repeat center center;
    background-size: cover;
}
.gallery-image-9{
    background: url("../../photos/miniatures/photo-009.jpg") no-repeat center center;
    background-size: cover;
}
.gallery-image-9-normal{
    background: url("../../photos/normal/photo-009.jpg") no-repeat center center;
    background-size: cover;
}
.gallery-image-8{
    background: url("../../photos/miniatures/photo-008.jpg") no-repeat center center;
    background-size: cover;
}
.gallery-image-8-normal{
    background: url("../../photos/normal/photo-008.jpg") no-repeat center center;
    background-size: cover;
}
.gallery-image-7{
    background: url("../../photos/miniatures/photo-007.jpg") no-repeat center center;
    background-size: cover;
}
.gallery-image-7-normal{
    background: url("../../photos/normal/photo-007.jpg") no-repeat center center;
    background-size: cover;
}
.gallery-image-6{
    background: url("../../photos/miniatures/photo-006.jpg") no-repeat center center;
    background-size: cover;
}
.gallery-image-6-normal{
    background: url("../../photos/normal/photo-006.jpg") no-repeat center center;
    background-size: cover;
}
.gallery-image-5{
    background: url("../../photos/miniatures/photo-005.jpg") no-repeat center center;
    background-size: cover;
}
.gallery-image-5-normal{
    background: url("../../photos/normal/photo-005.jpg") no-repeat center center;
    background-size: cover;
}
.gallery-image-4{
    background: url("../../photos/miniatures/photo-004.jpg") no-repeat center center;
    background-size: cover;
}
.gallery-image-4-normal{
    background: url("../../photos/normal/photo-004.jpg") no-repeat center center;
    background-size: cover;
}
.gallery-image-3{
    background: url("../../photos/miniatures/photo-003.jpg") no-repeat center center;
    background-size: cover;
}
.gallery-image-3-normal{
    background: url("../../photos/normal/photo-003.jpg") no-repeat center center;
    background-size: cover;
}
.gallery-image-2{
    background: url("../../photos/miniatures/photo-002.jpg") no-repeat center center;
    background-size: cover;
}
.gallery-image-2-normal{
    background: url("../../photos/normal/photo-002.jpg") no-repeat center center;
    background-size: cover;
}
.gallery-image-1{
    background: url("../../photos/miniatures/photo-001.jpg") no-repeat center center;
    background-size: cover;
}
.gallery-image-1-normal{
    background: url("../../photos/normal/photo-001.jpg") no-repeat center center;
    background-size: cover;
}