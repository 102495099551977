// ---------------------------------------------------------------------------------------------------------------------
// Mateusz Feć Photography Website
// ---------------------------------------------------------------------------------------------------------------------
// Author: Mateusz Feć
// e-mail: kontakt@mateuszfec.pl
// www: www.mateuszfec.pl
//
// www.mfecphoto.com
//
// Copyright © All Rights Reserved
// ---------------------------------------------------------------------------------------------------------------------
//                                                   Single Gallery
// ---------------------------------------------------------------------------------------------------------------------
// Page header image
.main-gallery-photo{
    max-height: 700px;
}

// Prevent from white spaces
main#main-gallery-content{
    line-height: 0;
}







// Opening section
#main-category-photo, #main-category-map{
    width: 100%;
    height: 50vh;
    min-height: 400px;
    text-align: center;
    overflow: hidden;
}
#main-category-photo{
    margin-bottom: 15px;
}



.center-with-map {
    @include vertical-horizontal-center-mix(50%, 40%);

    span{
        top: 8px;
    }
}



.category-photos{
    display: inline-block;

    .single-photo{
        a{
            .image-shadow{
                background: none;
            }
        }
        a:hover{
            .image-shadow{
                position: absolute;
                width: 100%;
                height: 100%;
                background: #000000;
                opacity: 0.5;
            }
        }
        a:hover::after{
            display: inline-block;
            width: 20px;
            height: 1px;
            content: '\ef62';
            position: absolute;
            font-family: 'IcoFont';
            font-size: 32px;
            color: $page_color_2;
            @include vertical-horizontal-center-mix();
        }
    }
}


// Single gallery
.my-gallery {
    width: 100%;
    //float: left;
}
.my-gallery img {
    /*width: 100%;*/
    /*height: 300px;*/
    min-width: 100%;
    height: 100%;
    display: none;
    /*height: 100%;*/
}
.my-gallery figure{
    margin: 0;
    padding: 0;
    height: 17vw;
    min-height: 120px;
}
.my-gallery figure a {
    display: block;
    width: 100%;
    height: 100%;
}
.my-gallery figcaption {
    display: none;
}
.pswp__ui{
    height: 100%;
}
.pswp__share--download{
    display: none!important;
}



#google-map {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}
.gmnoprint, .gm-style-cc{
    display: none;
}

// ---------------------------------------------------------------------------------------------------------------------
//                                               Responsive elements
// ---------------------------------------------------------------------------------------------------------------------
@media screen and (min-width: $xpemini){ // to 329px
}
@media screen and (min-width: $iphone){ // to 489px
}
@media screen and (min-width: $nexus){ // to 639px
}
@media screen and (min-width: $lumia){ // to 782px - MOBILE POINT
}
@media screen and (min-width: $ipad){ // to 1023px
}
@media screen and (min-width: $notebook){ // to 1199px
}
@media screen and (min-width: $desktop){ // to end
}