// ---------------------------------------------------------------------------------------------------------------------
//                                                   GLOBAL STYLES
// ---------------------------------------------------------------------------------------------------------------------
// Personal global styles for Mateusz Feć Photography Website
//
// Core file version: 2.0.1
// Modification date: 19.04.2018
//
// Author: Mateusz Feć
// e-mail: kontakt@mateuszfec.pl
// www: www.mateuszfec.pl
// url: http://www.mateuszfec.pl/
//
// www.mfecphoto.com
//
// Copyright © All Rights Reserved
// ---------------------------------------------------------------------------------------------------------------------
//                                               Theme name, data, etc.
// ---------------------------------------------------------------------------------------------------------------------
// NAME OF THEME:
// Full name for user view: Mateusz Feć Photography
// Code name without spaces: mf-photography

// WEBSITE FONTS:
// Default font: font-family: 'Montserrat', sans-serif;
// Headers: font-family: 'Sriracha', cursive;
// Extra: font-family: 'Maven Pro', sans-serif;

// WEBSITE FONTS:
$page_font_default: 'Montserrat', sans-serif;
$page_font_headers: 'Maven Pro', sans-serif;
//$page_font_extra: 'Sriracha', cursive;
//$page_font_extra: 'Alegreya', serif;
$page_font_extra: 'Charm', cursive;
// ---------------------------------------------------------------------------------------------------------------------
//                                                   Global colors
// ---------------------------------------------------------------------------------------------------------------------
$page_color_1: #131313;
$page_color_2: #ffffff;
$page_color_3: #a2a1a1;
$page_color_4: #111111;
$page_color_5: #fefefe;
$page_color_6: #4d4c4c;
// ---------------------------------------------------------------------------------------------------------------------
//                                               Standard animations
// ---------------------------------------------------------------------------------------------------------------------
$standard_animation_short_time: 0.2s;
$standard_animation_time: 0.3s;
$standard_animation_long_time: 0.6s;

.standard-animation-short{
    -webkit-transition: all $standard_animation_short_time ease-in-out;
    -moz-transition: all $standard_animation_short_time ease-in-out;
    -o-transition: all $standard_animation_short_time ease-in-out;
    transition: all $standard_animation_short_time ease-in-out;
}
.standard-animation{
    -webkit-transition: all $standard_animation_time ease-in-out;
    -moz-transition: all $standard_animation_time ease-in-out;
    -o-transition: all $standard_animation_time ease-in-out;
    transition: all $standard_animation_time ease-in-out;
}
.standard-animation-long{
    -webkit-transition: all $standard_animation_long_time ease-in-out;
    -moz-transition: all $standard_animation_long_time ease-in-out;
    -o-transition: all $standard_animation_long_time ease-in-out;
    transition: all $standard_animation_long_time ease-in-out;
}

@mixin standard-animation-mix($property: all, $animation_time: $standard_animation_time, $type_of_effect: ease-in-out){
    -webkit-transition: $property $animation_time $type_of_effect;
    -moz-transition: $property $animation_time $type_of_effect;
    -o-transition: $property $animation_time $type_of_effect;
    transition: $property $animation_time $type_of_effect;
    // Optimization for opacity changing
    @if $property == opacity {
        will-change: opacity;
    }
}
// ---------------------------------------------------------------------------------------------------------------------
//                                              Center absolute element
// ---------------------------------------------------------------------------------------------------------------------
.vertical-center{
    top: 50%;
    -moz-transform: translate(-0%, -50%);
    -ms-transform: translate(-0%, -50%);
    -webkit-transform: translate(-0%, -50%);
    transform: translate(-0%, -50%);
}
.horizontal-center{
    left: 50%;
    -moz-transform: translate(-50%);
    -ms-transform: translate(-50%);
    -webkit-transform: translate(-50%);
    transform: translate(-50%);
}
.vertical-horizontal-center{
    top: 50%;
    left: 50%;
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

@mixin vertical-horizontal-center-mix($left_val: 50%, $top_val: 50%){
    // Only horizontal
    @if $left_val == 0 and $top_val != 0{
        top: $top_val;
        -moz-transform: translate(0, -$top_val);
        -ms-transform: translate(0, -$top_val);
        -webkit-transform: translate(0, -$top_val);
        transform: translate(0, -$top_val);
    }
    // Only vertical
    @elseif $left_val != 0 and $top_val == 0{
        left: $left_val;
        -moz-transform: translate(-$left_val);
        -ms-transform: translate(-$left_val);
        -webkit-transform: translate(-$left_val);
        transform: translate(-$left_val);
    }
    // Vertical + horizontal
    @elseif $left_val > 0 and $top_val > 0 {
        left: $left_val;
        top: $top_val;
        -moz-transform: translate(-$left_val, -$top_val);
        -ms-transform: translate(-$left_val, -$top_val);
        -webkit-transform: translate(-$left_val, -$top_val);
        transform: translate(-$left_val, -$top_val);
    }
    // Default: vertical + horizontal
    @else {
        top: 50%;
        left: 50%;
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
}
// ---------------------------------------------------------------------------------------------------------------------
//                                            Our two basic types of buttons
// ---------------------------------------------------------------------------------------------------------------------
@mixin my-button{
    width: 90%;
    max-width: 200px;
    display: inline-block;
    margin: 10px 10px 0 10px;
    padding: 12px 10px;
    text-transform: uppercase;
    word-wrap: break-word;
    text-decoration: none;
    outline: none;
}

a.primary-button{
    @include my-button();
    color: $page_color_2;
    background: none;
    border: 2px $page_color_2 solid;
    @include standard-animation-mix(all, $standard_animation_time, linear);
}
a.primary-button:hover, a.primary-button:focus, a.primary-button:active{
    color: $page_color_1;
    background: $page_color_2;
}
a.secondary-button{
    @include my-button();
    color: $page_color_1;
    background: $page_color_2;
    @include standard-animation-mix();
}
a.secondary-button:hover, a.secondary-button:focus, a.secondary-button:active{
    color: $page_color_2;
    background: $page_color_1;
}
// ---------------------------------------------------------------------------------------------------------------------
//                                                       BODY
// ---------------------------------------------------------------------------------------------------------------------
body{
    min-width: 240px;
    margin: 0;
    background: $page_color_1;
    color: $page_color_2;
    font-family: 'Montserrat', sans-serif;
}
// ---------------------------------------------------------------------------------------------------------------------
//                                        Default styles for WYSIWYG content
// ---------------------------------------------------------------------------------------------------------------------
.wysiwyg-standard-content{
    // styles ...
}
// ---------------------------------------------------------------------------------------------------------------------
//                                             Global fixes for website
// ---------------------------------------------------------------------------------------------------------------------
// HTML, BODY - IE, Firefox 3+
html, body {min-height: 100%;}

// Important for most of website solutions
*{position: relative;}

// Add hidden class for SEO elements for example
.hidden-element{display: none;}

// Change last child float in Foundation
.columns:last-child:not(:first-child){float: left;}