// ---------------------------------------------------------------------------------------------------------------------
// Mateusz Feć Photography Website
// ---------------------------------------------------------------------------------------------------------------------
// Author: Mateusz Feć
// e-mail: kontakt@mateuszfec.pl
// www: www.mateuszfec.pl
//
// www.mfecphoto.com
//
// Copyright © All Rights Reserved
// ---------------------------------------------------------------------------------------------------------------------
//                                                    Main Slider
// ---------------------------------------------------------------------------------------------------------------------
// Slider elements
#slider-wrapper{
    #main-slider{
        width: 100%;
        height: 100vh;
        min-height: 200px;
        overflow: hidden;
        outline: none;
        div{
            height: 100%;
            outline: none;
        }
        // Slider cursor effect
        .slick-slide{
            //cursor: grab;
        }

        // Main container inside slider
        .main-slider-content{
            position: absolute;
            display: inline-block;
            height: auto;
            top: auto;
            text-align: center;
            background: url("../../assets/images/test.png") repeat;
            padding: 15px 60px 20px 60px;

            div.first-box{
                //background: url("../../assets/images/test.png") repeat;
                //padding: 10px 35px 15px 75px;
            }
            div.second-box{
                //background: $page_color_1;
                //margin: 15px 0 0 75px;
                width: auto;
                a{
                    text-align: center;
                }
            }

            span, h3{
                margin: 0;
            }
            span{
                font-size: 1.5rem;
                font-family: $page_font_extra;
                font-weight: 500;
                font-style: italic;
            }
            h3{
                font-family: $page_font_headers;
                font-weight: 700;
            }
            a{
                text-transform: uppercase;
                font-size: 0.85rem;
                font-weight: 600;
                white-space: nowrap;
                min-width: 180px;
            }
        }

        // Arrows
        //.main-slider-arrow{
        //    position: absolute;
        //    width: 33px;
        //    height: 100px;
        //    z-index: 2;
        //    cursor: pointer;
        //    @include vertical-horizontal-center-mix(0, 50%);
        //    @include standard-animation-mix(all, $standard_animation_short_time ,ease-in-out);
        //}
        //.slider-arrow-prev{
        //    left: 15px;
        //    right: auto;
        //    background: url("../../assets/images/arrow-left.png") no-repeat center center;
        //    background-size: contain;
        //}
        //.slider-arrow-prev:hover, .slider-arrow-prev:active, .slider-arrow-prev:focus{
        //    background: url("../../assets/images/arrow-left-2.png") no-repeat center center;
        //    background-size: contain;
        //}
        //.slider-arrow-next{
        //    left: auto;
        //    right: 15px;
        //    background: url("../../assets/images/arrow-right.png") no-repeat center center;
        //    background-size: contain;
        //}
        //.slider-arrow-next:hover, .slider-arrow-next:active, .slider-arrow-next:focus{
        //    background: url("../../assets/images/arrow-right-2.png") no-repeat center center;
        //    background-size: contain;
        //}
    }
}
// ---------------------------------------------------------------------------------------------------------------------
//                                           Responsive elements (width)
// ---------------------------------------------------------------------------------------------------------------------
@media screen and (min-width: $xpemini){ // to 329px
    // Main Gallery title
    .main-slider-content {
        width: 100%;
        bottom: 0;
        left: 0;
        div.first-box{
            display: none;
        }
        .second-box a{
            margin: 8px 0 0 0;
        }
    }

    // Arrows
    .main-slider-arrow {
        display: none !important;
    }
}
@media screen and (min-width: $iphone){ // to 489px
}
@media screen and (min-width: $nexus){ // to 639px
    // Arrows
    .main-slider-arrow {
        display: inline-block !important;
    }
}
@media screen and (min-width: $lumia){ // to 782px - MOBILE POINT
    // Main Gallery title
    .main-slider-content {
        width: auto;
        bottom: 25px;
        left: 25px;
        max-width: 85%;
        border-radius: 0 20px 0 0;
        div.first-box{
            display: block;
        }
        .second-box a{
            margin: 15px 0 0 0;
        }
        h3 {
            font-size: 2rem;
            line-height: 2rem;
        }
    }
}
@media screen and (min-width: $ipad){ // to 1023px
    // Main Gallery title
    .main-slider-content {
        h3 {
            font-size: 2.5rem;
            line-height: 2.5rem;
        }
    }
}
@media screen and (min-width: $notebook){ // to 1199px
}
@media screen and (min-width: $desktop){ // to end
}
// ---------------------------------------------------------------------------------------------------------------------
//                                             Responsive elements (height)
// ---------------------------------------------------------------------------------------------------------------------
@media all and (min-height: 0px) {

}
@media all and (min-height: 380px) {

}
@media all and (min-height: 500px) {

}