// ---------------------------------------------------------------------------------------------------------------------
//                                                       INFO
// ---------------------------------------------------------------------------------------------------------------------
#photo-categories{
    // Chronologically title
    span#events-title{
        display: inline-block;
        width: 100%;
        text-align: center;
        font-weight: 500;
        font-style: italic;
        font-family: $page_font_extra;
    }

    // Categories tiles
    .categories{
        display: inline-block;
        width: 100%;
        .single-category{
            height: 32vh;
            padding: 0;
            min-height: 185px;

            .drape{
                position: absolute;
                width: 100%;
                height: 100%;
                background: #000000;
                opacity: 0.72;
            }

            // Category tile
            .category-wrapper{
                position: absolute;
                width: 100%;
                height: 100%;
                padding: 1.575rem;
                text-transform: capitalize;
                .category-border{
                    width: 100%;
                    height: 100%;
                    border: 1px $page_color_5 solid;
                    border-radius: 10px;
                }

                // Category label
                div.category-label{
                    position: absolute;
                    display: inline-block;
                    text-align: center;
                    padding: 1.575rem;
                    @include standard-animation-mix(all, 0.75s, ease);
                    //background: red;
                    top: 50%;
                    left: 50%;
                    -moz-transform: translate(-50%, -50%);
                    -ms-transform: translate(-50%, -50%);
                    -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);

                    span{
                        color: $page_color_2;
                        margin: 0;
                        font-family: $page_font_extra;
                        white-space: nowrap;
                        word-break: keep-all;
                        font-weight: 500;
                        font-style: italic;
                        text-shadow: 2px 2px 8px $page_color_1;
                    }
                    h3{
                        color: $page_color_2;
                        margin: 0;
                        font-family: $page_font_headers;
                        font-weight: 700;
                        text-shadow: 2px 2px 8px $page_color_1;
                    }
                }
            }
        }

        // On tile:hover action
        .single-category:hover, .single-category:focus{
            .drape {
                opacity: 0;
                background: none;
            }
            .category-border{
                border: none;
            }
            .category-label{
                top: 100% !important; // todo: remove !important close
                left: 0 !important;
                -moz-transform: translate(0%, -100%) !important;
                -ms-transform: translate(0%, -100%) !important;
                -webkit-transform: translate(0%, -100%) !important;
                transform: translate(0%, -100%) !important;
            }
        }
    }
}
// ---------------------------------------------------------------------------------------------------------------------
//                                                 Responsive elements
// ---------------------------------------------------------------------------------------------------------------------
@media screen and (min-width: $xpemini){ // to 329px
    // Category label
    .category-label{
        span{
            font-size: 1.2rem;
            line-height: 1.2rem;
        }
        h3{
            font-size: 1.5rem;
            line-height: 1.5rem;
        }

        // Special class for long titles
        span.long-tile-span{
            font-size: 1rem;
            line-height: 1rem;
        }
        h3.long-tile-title{
            font-size: 1.5rem;
            line-height: 1.75rem;
        }
    }

    // Chronologically title
    span#events-title{
        font-size: 2.5rem;
        line-height: 2.5rem;
        padding: 50px 0 40px 0;
    }
}
@media screen and (min-width: $iphone){ // to 489px
    // Category label
    .category-label{
        span{
            font-size: 1.5rem;
            line-height: 1.5rem;
        }
        h3{
            font-size: 2.5rem;
            line-height: 2.5rem;
        }

        // Special class for long titles
        span.long-tile-span{
            font-size: 1.3rem;
            line-height: 1.3rem;
        }
        h3.long-tile-title{
            font-size: 1.8rem;
            line-height: 1.95rem;
        }
    }

}
@media screen and (min-width: $nexus){ // to 639px
    // Category label
    .category-label{
        span{
            font-size: 1.75rem;
            line-height: 1.75rem;
        }
        h3{
            font-size: 2.65rem;
            line-height: 2.65rem;
        }

        // Special class for long titles
        span.long-tile-span{
            font-size: 1.5rem;
            line-height: 1.4rem;
        }
        h3.long-tile-title{
            font-size: 2rem;
            line-height: 2.15rem;
        }
    }

    // Chronologically title
    span#events-title{
        font-size: 3rem;
        line-height: 3rem;
    }
}
@media screen and (min-width: $lumia){ // to 782px - MOBILE POINT
    // Category label
    .category-label{
        span{
            font-size: 1.5rem;
            line-height: 1.5rem;
        }
        h3{
            font-size: 2.5rem;
            line-height: 2.5rem;
        }

        // Special class for long titles
        span.long-tile-span{
            font-size: 1.3rem;
            line-height: 1.2rem;
        }
        h3.long-tile-title{
            font-size: 1.7rem;
            line-height: 1.85rem;
        }
    }

}
@media screen and (min-width: $ipad){ // to 1023px
    // Category label
    .category-label{
        // Special class for long titles
        span.long-tile-span{
            font-size: 1.5rem;
            line-height: 1.4rem;
        }
        h3.long-tile-title{
            font-size: 1.9rem;
            line-height: 2.05rem;
        }
    }
}
@media screen and (min-width: $notebook){ // to 1199px
    // Category label
    .category-label{
        span{
            font-size: 1.2rem;
            line-height: 1.2rem;
        }
        h3{
            font-size: 1.85rem;
            line-height: 1.85rem;
        }

        // Special class for long titles
        span.long-tile-span{
            font-size: 1.3rem;
            line-height: 1.2rem;
        }
        h3.long-tile-title{
            font-size: 1.65rem;
            line-height: 1.75rem;
        }
    }
}
@media screen and (min-width: $desktop){ // to end
    // Category label
    .category-label{
        span{
            font-size: 1.5rem;
            line-height: 1.5rem;
        }
        h3{
            font-size: 2.35rem;
            line-height: 2.35rem;
        }

        // Special class for long titles
        span.long-tile-span{
            font-size: 1.4rem;
            line-height: 1.4rem;
        }
        h3.long-tile-title{
            font-size: 1.85rem;
            line-height: 2rem;
        }
    }

    // Chronologically title
    span#events-title{
        font-size: 3.5rem;
        line-height: 3.5rem;
        padding: 70px 0 50px 0;
    }
}